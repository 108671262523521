import React, { useEffect } from "react"
import Helmet from "react-helmet"

// import "../utils/scripts/smoothScroll"

import Header from "../components/MainPage/Header"
import Products from "../components/MainPage/Products"
import About from "../components/MainPage/About"
import ContactUs from "../components/MainPage/ContactUs"
import Footer from "../components/MainPage/Footer"
import SEO from "../components/MainPage/Seo"

// import "../utils/animation"

import "../utils/css/homePage.css"
import { top_right } from "../../static/icons/top-right.svg"
import "../utils/css/blog.css"
// import fogLogo from '../../static/icons/FOG_logo.svg';

const HomePage = () => {
  // useEffect(() => {
  //   // Redirecting to the FOG website
  //   window.location.href = 'https://futureofgaming.tech';
  // }, []);
  
  return (
    <div className="home">
      <Header />
      <div className="title">
        <span className="name1">FOG</span>
        <br />
        <p className="tag2">Future of Gaming</p>
        <span className="tag1">Next-Gen Attractions and Arcade Games</span>
        <br></br>
        <div className="links">
          <a href="#products">Explore Games</a>
          <a href="#contact">Reach Out</a>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <div>
    <HomePage />
    <Products />
    <About />
    <ContactUs />
    <Footer />
    <SEO />
    {/* <Header />
        <FilterNav />
        <HomePage>
            <ArticlesGrid />
            <Footer />
        </HomePage> */}
  </div>
)
